<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <title>Butler</title>
    <meta content="" name="description" />
    <meta content="" name="keywords" />
    <link rel="stylesheet" href="./support.component.scss" />
  </head>
  <body>
    <img
      src="../../../../assets/under_maintenance/img/butler.png"
      alt="Butler Logo"
      class="logo"
    />
    <!-- Lollipops lol -->
    <img
      src="../../../../assets/under_maintenance/img/lolipop.png"
      alt="Lollipop"
      class="lolipop lolipop-left"
    />
    <img
      src="../../../../assets/under_maintenance/img/lolipop2.png"
      alt="Lollipop 2"
      class="lolipop lolipop-right"
    />

    <!-- Navigation Bar -->
    <nav class="navbar">
      <ul class="nav-links">
        <li><a href="#">Home</a></li>
        <li><a href="privacy" class="active">Privacy Policy</a></li>
        <li><a href="contact-us">Contact Us</a></li>
      </ul>
      <div class="underline"></div>
    </nav>

    <!-- Privacy Policy Content -->
    <div class="privacy-container">
      <div class="image-container">
        <img
          src="../../../../assets/under_maintenance/img/privacy.png"
          alt="Privacy Policy"
          class="centered-image"
        />
      </div>
      <div class="privacy-text">
        <h2>1. Introduction</h2>
        <p>
          Welcome to Butler! Our privacy policy outlines how we collect, use,
          and protect your personal information.
        </p>

        <h2>2. Information We Collect:</h2>
        <p>We collect the following types of information:</p>
        <p>
          <strong>Personal Identification Information:</strong> This includes
          your name, email address, phone number, etc. We collect this
          information solely for the purpose of maintaining your account with
          us.
        </p>

        <h2>3. Session Details</h2>
        <p>We retain your session details for the following purposes:</p>
        <ul>
          <li>Keeping you logged in to our app.</li>
          <li>
            Determining whether you have opened the app for the first time.
          </li>
        </ul>
        <p>
          Please note that, apart from these session details, we do not store
          any cookies or other data on your end.
        </p>

        <h2>4. How We Use Your Information</h2>
        <p>We use the information we collect in various ways, including to:</p>
        <ul>
          <li>Provide, operate, and maintain our app.</li>
          <li>Improve, personalize, and expand our app.</li>
          <li>Understand and analyze how you use our app.</li>
          <li>Develop new products, services, features, and functionality.</li>
        </ul>
        <p>
          Please be assured that we do not use your information for advertising
          purposes.
        </p>

        <h2>5. How We Share Your Information</h2>
        <p>
          We do not sell, trade, or use your personal identification information
          for advertising. We also do not share your personal information with
          others.
        </p>

        <h2>6. Changes To This Privacy Policy</h2>
        <p>
          We may update our Privacy Policy from time to time. Any changes will
          be communicated by posting the updated Privacy Policy on this page.
        </p>

        <h2>7.Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please feel free
          to contact us at
          <a href="mailto:help@butlerapp.info">help@butlerapp.info</a>.
        </p>
      </div>
    </div>
    <script>
      function toggleContent() {
        const imageContainer = document.querySelector(".image-container");
        const privacyText = document.querySelector(".privacy-text");

        if (window.innerWidth <= 768) {
          if (imageContainer) imageContainer.style.display = "none";
          if (privacyText) privacyText.style.display = "block";
        } else {
          if (imageContainer) imageContainer.style.display = "block";
          if (privacyText) privacyText.style.display = "none";
        }
      }

      window.onload = toggleContent;

      window.onresize = toggleContent;
    </script>
  </body>
</html>
