<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <title>Butler</title>
    <meta content="" name="description" />
    <meta content="" name="keywords" />
    <link rel="stylesheet" href="./under-maintance.component.scss" />
  </head>

  <body>
    <img
      src="../../../../assets/under_maintenance/img/butler.png"
      alt="Butler Logo"
      class="logo"
    />
    <!-- Lollipops -->
    <img
      src="../../../../assets/under_maintenance/img/lolipop.png"
      alt="Lollipop"
      class="lolipop lolipop-left"
    />
    <img
      src="../../../../assets/under_maintenance/img/lolipop2.png"
      alt="Lollipop 2"
      class="lolipop lolipop-right"
    />

    <!-- Navigation Bar -->
    <nav class="navbar">
      <ul class="nav-links">
        <li><a href="" class="active">Home</a></li>
        <li><a href="privacy">Privacy Policy</a></li>
        <li><a href="contact-us">Contact Us</a></li>
      </ul>
      <div class="underline"></div>
    </nav>

    <!-- Centered Images -->
    <div class="image-container">
      <img
        src="../../../../assets/under_maintenance/img/welcome.png"
        alt="Welcome"
        class="centered-image"
      />
    </div>
    <div class="image-container">
      <img
        src="../../../../assets/under_maintenance/img/welcome3.png"
        alt="Welcome 2"
        class="centered-image"
      />
    </div>
    <div class="image-container">
      <img
        src="../../../../assets/under_maintenance/img/welcome2.png"
        alt="Welcome 3"
        class="centered-image"
      />
    </div>
  </body>
</html>
