import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';

import {CustomBreakPointsProvider} from 'app/layout/custom-breakpoints';
import {VerticalLayoutModule} from 'app/layout/vertical/vertical-layout.module';

@NgModule({
    imports: [FlexLayoutModule.withConfig({disableDefaultBps: true}), VerticalLayoutModule],
    providers: [CustomBreakPointsProvider],
    exports: [VerticalLayoutModule],
    declarations: []
})
export class LayoutModule {
}
