<ng-container>
  <ng-container *ngFor="let item of menu">
    <ng-container *ngIf="checkPermission(item)">
      <li
        *ngIf="item.type == 'section'"
        core-menu-vertical-section
        [item]="item"
        class="navigation-header"
      ></li>

      <ng-container *ngIf="item.type == 'section'">
        <ng-container *ngFor="let item of item.children">
          <li
            core-menu-vertical-item
            *ngIf="item.type === 'item'"
            [item]="item"
            [ngClass]="{ disabled: item.disabled === true }"
            [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
            [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
          >
            <span
              [routerLink]="item.openInNewTab ? [] : [item.url]"
              class="d-none"
            ></span>
          </li>
          <li
            core-menu-vertical-collapsible
            *ngIf="item.type == 'collapsible'"
            [item]="item"
            class="nav-item has-sub"
          ></li>
        </ng-container>
      </ng-container>

      <li
        core-menu-vertical-item
        *ngIf="item.type == 'item'"
        [item]="item"
        [ngClass]="{ disabled: item.disabled === true }"
        [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
        [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      >
        <span
          [routerLink]="item.openInNewTab ? [] : [item.url]"
          class="d-none"
        ></span>
      </li>

      <li
        core-menu-vertical-collapsible
        *ngIf="item.type == 'collapsible'"
        [item]="item"
        class="nav-item has-sub"
      ></li>
    </ng-container>
  </ng-container>
</ng-container>
